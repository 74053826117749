import React from "react"

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null
  }
  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content has-background-white p-6">
        {children}
      </div>
      <button
        onClick={onClose}
        className="modal-close is-large"
        aria-label="close"></button>
    </div>
  )
}

export default Modal
