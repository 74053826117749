import React from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Modal from "../../../components/modal"

class WorshopJsdIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeModal: null,
    }
  }

  openModal = (modalName) => {
    this.setState({ activeModal: modalName })
  }

  closeModal = () => {
    this.setState({ activeModal: null })
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const post = data.allMarkdownRemark.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.seoTitle || post.frontmatter.title}
          description={post.frontmatter.seoDescription}
        />
        <div className="content">
          <section className="hero">
            <div className="hero-body">
              <h1 className="title is-1">Workshoptag</h1>
              <h2 className="subtitle">Am 25. SEPTEMBER 2025 VON 9 - 17 UHR</h2>
              <hr></hr>
              <div className="columns">
                <div className="column pr-6 mb-6">
                  <figure className="image is-128x128 ml-1">
                    <img
                      className="is-rounded"
                      src={data.imgJulia.nodes[0].childImageSharp.fluid.src}
                    />
                  </figure>
                  <h3 className="subtitle is-5">
                    Tech-Teams führen - Wie Du Dein Team mit dienlicher Führung
                    zu exzellenten Ergebnissen führst
                  </h3>
                  <h4 className="subtitle is-6">Julia Dellnitz, smidig eGbR</h4>
                  <p>
                    In diesem Workshop lernst du bewährte Führungsansätze für
                    das Tech-Umfeld kennen, reflektierst deine eigene
                    Führungsrolle und entwickelst Ideen für bessere Teamführung.
                    Du beschäftigst dich mit „dienlicher Führung“ und erfährst,
                    wie du Selbstorganisation förderst, um exzellente Ergebnisse
                    zu erzielen.
                    <br></br>
                    <br></br>
                    <a onClick={() => this.openModal("modal1")}>Mehr...</a>
                  </p>
                  <Modal
                    isOpen={this.state.activeModal === "modal1"}
                    onClose={this.closeModal}
                  >
                    <h3>
                      Tech-Teams führen - Wie Du Dein Team mit dienlicher
                      Führung zu exzellenten Ergebnissen führst
                    </h3>
                    <p>
                      Wer Software im Team entwickelt, muss Kreativität,
                      Know-how und Anpassungsfähigkeit aller Beteiligten
                      entfalten und nutzen. Nur so können innovative Produkte
                      und Dienste in einem hochdynamischen Tech-Umfeld
                      entstehen. Als Führungskraft übernimmst Du dabei
                      Verantwortung – für Dich selbst, für andere und für die
                      gemeinsame Arbeit. Selbstreflexion und geeignete Methoden
                      machen Dir diesen Job deutlich leichter.
                    </p>
                    <p>
                      In diesem Workshop lernst Du verschiedene Führungsansätze
                      kennen, die sich im Tech-Umfeld bewährt haben. Du wendest
                      sie auf eigene Führungssituationen an und entwickelst
                      Ideen, wie Du die Führungsarbeit in Deinem Team künftig
                      gestalten kannst. Du setzt Dich dabei mit dem Konzept der
                      „dienlichen Führung“ auseinander und klärst deine eigene
                      Führungsrolle. Du erfährst, wie Du mehr Selbstorganisation
                      ermöglichen und damit einen Rahmen für exzellente
                      Team-Ergebnisse schaffen kannst.
                    </p>
                    <h3>Level</h3>
                    <p>
                      Erfahrung als Führungskraft, Projektleitung oder Scrum
                      Master:in
                    </p>
                    <h3>Vorkenntnisse</h3>
                    <p>
                      Vertraut mit agilen Grundlagen, Grundkenntnisse in
                      Kommunikation und Konfliktmanagement
                    </p>
                    <h3>Benötigte Hardware / Software</h3>
                    <p>Keine</p>
                    <h3>Trainerin</h3>
                    <p>
                      Julia ist Geschäftsführerin und agile Projekt Expertin bei
                      smidig. Seit 2004 berät und qualifiziert sie
                      IT-Führungskräfte, optimiert agile Projektstrukturen,
                      hilft auf dem Weg zur Selbstorganisation und entwickelt
                      Arbeitsspiele. Julia schreibt Fachbücher für den Rheinwerk
                      Verlag und ist Ambassadorin für Agile Leadership im Women
                      Leadership Network PANDA.
                    </p>
                    <p>
                      {" "}
                      <a
                        href="/downloads/day/JSD2025_Workshop-Tech-Teams-fuehren.pdf"
                        target="_blank"
                      >
                        Workshop-Flyer zum Download (PDF, 1 MB)
                      </a>
                    </p>
                  </Modal>
                </div>
                <div className="column pr-6 mb-6">
                  <figure className="image is-128x128 ml-1">
                    <img
                      className="is-rounded"
                      src={data.imgStephan.nodes[0].childImageSharp.fluid.src}
                    />
                  </figure>
                  <h3 className="subtitle is-5">
                    Der Traum vom Perfekten System - von der grünen Wiese zur
                    Realität
                  </h3>
                  <h4 className="subtitle is-6">
                    Stephan Pirnbaum, BUSCHMAIS GbR
                  </h4>
                  <p>
                    In diesem Workshop untersuchen wir, ob der Traum vom
                    perfekten Software-System erreichbar ist. Wir betrachten
                    Greenfield-Ansätze und Methoden zur Verbesserung bestehender
                    Systeme, nutzen Strategic Domain-Driven Design (DDD) für
                    nachhaltige Architekturen und zeigen, wie moderne Tools und
                    Methoden auch komplexe Bestandsysteme verbessern können.
                    <br></br>
                    <br></br>
                    <a onClick={() => this.openModal("modal2")}>Mehr...</a>
                  </p>
                  <Modal
                    isOpen={this.state.activeModal === "modal2"}
                    onClose={this.closeModal}
                  >
                    <h3>
                      Der Traum vom Perfekten System - von der grünen Wiese zur
                      Realität - Wie man ein perfektes Softwareprojekt richtig
                      startet und lebendig hält.
                    </h3>
                    <p>
                      Träumen wir nicht alle von dem perfekten Software-System?
                      Ob in neuen Projekten oder im laufenden Betrieb: Der
                      Wunsch, alles besser zu machen, bleibt bestehen. Aber wie
                      oft holen uns der Projektalltag und die Realität ein und
                      wir finden uns den gleichen Problemen des letzten Projekts
                      gegenüber gestellt: schlechte Wartbarkeit, mangelhafte
                      Testbarkeit, komplizierte Releaseprozesse und
                      Produktionsprobleme, die durch komplexe Code-Strukturen
                      und unvollständige Dokumentation schwer zu lösen sind.
                      Aber muss das so?
                    </p>
                    <p>
                      In diesem Workshop gehen wir der Frage nach, ob der Traum
                      vom perfekten System wirklich unerreichbar ist. Gemeinsam
                      beleuchten wir nicht nur Greenfield-Ansätze, sondern auch
                      Methoden und Werkzeuge, die bestehende Systeme nachhaltig
                      verbessern können. Wir nutzen Strategic Domain-Driven
                      Design (DDD), um leistungsfähige Architekturen auf Basis
                      fachlicher Anforderungen zu entwerfen, und zeigen auf, wie
                      diese dokumentiert und nachhaltig umgesetzt werden können,
                      ohne einer Erosion zum Opfer zu fallen.
                    </p>
                    <p>
                      Interaktiv entwerfen wir ein Softwaresystem, skizzieren
                      dessen Dokumentation und Umsetzung und zeigen praxisnah,
                      wie mit modernen Tools und Methoden, Entwickler und
                      Architekten ihren Traum vom perfekten System – selbst im
                      komplexen Bestand – Wirklichkeit werden lassen können.
                    </p>
                    <h3>Zielgruppe</h3>
                    <p>
                      Entwicklungsnahe Architekt*innen bzw. Entwickler*innen Die
                      Teilnehmenden werden in die Lage versetzt, strukturiert
                      Architektur von Softwaresystemen zu entwerfen bzw. an neue
                      Anforderungen anzupassen und somit langfristig die
                      Qualität der Anwendung zu sichern.
                    </p>
                    <h3>Level</h3>
                    <p>Beginner - Intermediate</p>
                    <h3>Vorkenntnisse</h3>
                    <p>
                      Du solltest Erfahrungen in der Softwareentwicklung, in der
                      Entwicklung von Greenfield-Projekten oder von gewachsenen
                      Bestandssystemen besitzen.
                    </p>
                    <h3>Benötigte Hardware / Software</h3>
                    <p>Eigenes Notebook mit IDE, Java (ab 17), Maven, Git</p>
                    <h3>Trainer</h3>
                    <p>
                      Stephan ist Consultant bei der BUSCHMAIS GbR und steht
                      täglich vor den Herausforderungen bestehender sowie neuer
                      Softwareprojekte unterschiedlicher Kunden. Ausgestattet
                      mit den richtigen Tools und Methoden, hilft er Kunden
                      leidenschaftlich gern, diese Herausforderungen effizient
                      und nachhaltig zu bewältigen, um langfristigen Wert zu
                      sichern und zu schaffen. Die in zahlreichen Projekten
                      gewonnenen Erfahrungen teilt er regelmäßig auf
                      Konferenzen, um auch über sein direktes Projektgeschäft
                      hinaus einen Mehrwert zu bieten.
                    </p>
                    <p>
                      {" "}
                      <a
                        href="/downloads/day/JSD2025_Workshop-Traum-vom-perfekten-System.pdf"
                        target="_blank"
                      >
                        Workshop-Flyer zum Download (PDF, 1 MB)
                      </a>
                    </p>
                  </Modal>
                </div>
                <div className="column pr-6">
                  <figure className="image is-128x128 ml-1">
                    <img
                      className="is-rounded"
                      src={data.imgOliver.nodes[0].childImageSharp.fluid.src}
                    />
                  </figure>
                  <h3 className="subtitle is-5">
                    Taktisches Domain-Driven Design mit Java & Spring
                  </h3>
                  <h4 className="subtitle is-6">Oliver Drotbohm, Broadcom</h4>
                  <p>
                    Der Workshop behandelt die Umsetzung von Domain-Driven
                    Design (DDD) in Java. Dabei wird die jMolecules-Bibliothek
                    für DDD-Konzepte und das Spring Modulith-Projekt für
                    Modulgestaltung, Event-Interaktion, Testbarkeit und
                    Dokumentation vorgestellt.
                    <br></br>
                    <br></br>
                    <a onClick={() => this.openModal("modal3")}>Mehr...</a>
                  </p>
                  <Modal
                    isOpen={this.state.activeModal === "modal3"}
                    onClose={this.closeModal}
                  >
                    <h3>Taktisches Domain-Driven Design mit Java & Spring</h3>
                    <p>
                      Die Kernbausteine des taktischen Designs in Domain-Driven
                      Design (DDD) definieren atomare Designkonzepte für
                      Domänenmodelle. Sie definieren Semantik, Regeln und leiten
                      Entwickler:innen dabei, fachlichen Code zu strukturieren
                      und so komplexe Geschäftslogik zu implementieren. Die
                      Umsetzung in Java birgt dabei jedoch einige technische
                      Herausforderungen.
                    </p>
                    <p>
                      In diesem Workshop betrachten wir verschiedene Ansätze und
                      Werkzeuge, die Entwickler:innen dabei unterstützen
                      reichhaltige Domänenmodelle in Java zu implementieren: die
                      jMolecules Bibliothek ermöglicht es, DDD Konzepte direkt
                      in Code auszudrücken und bietet darüber hinaus Integration
                      in weitverbreitete Technologien wie Spring, Jackson und
                      Persistenztechnologien. Für Spring Boot Applikationen
                      unterstützt das Spring Modulith Projekt Entwickler:innen
                      bei der Umsetzung von Modulen, der Interaktion dieser über
                      Events, bei der individuellen Testbarkeit und dem Erzeugen
                      von Dokumentation über diese.
                    </p>
                    <h3>Level</h3>
                    <p>
                      Fortgeschrittene. Entwickelnde mit Interesse an
                      Architekturthemen.
                    </p>
                    <h3>Vorkenntnisse</h3>
                    <p>Grundkenntnisse Java und Domain-Driven Design.</p>
                    <h3>Benötigte Hardware / Software</h3>
                    <p>Wird noch bekannt gegeben.</p>
                    <h3>Trainer</h3>
                    <p>
                      Oliver Drotbohm ist Teil des Spring Engineering Teams bei
                      Broadcom. Seine Arbeitsschwerpunkte liegen im Bereich
                      Softwarearchitektur, Domain-Driven Design, REST, Spring
                      und Persistenztechnologien. Sein neues Buch „Modulithic
                      Applications with Spring“ erscheint 2025.
                    </p>
                    <p>
                      {" "}
                      <a
                        href="/downloads/day/JSD2025_Workshop-Taktisches-DDD.pdf"
                        target="_blank"
                      >
                        Workshop-Flyer zum Download (PDF, 1 MB)
                      </a>
                    </p>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
          <hr></hr>
          <section className="hero">
            <div className="hero-body">
              <h2 className="title is-3">Klingt interessant?</h2>
              <p>
                Um an einem der Workshops teilnehmen zu können, buche bitte ein
                Kombiticket für den JUG Saxony Day. Dieses beinhaltet den
                Workshop- und Konferenztag. Den Flyer, auf dem alle Workshops
                kurz präsentiert sind, findest du noch einmal hier zum{" "}
                <a
                  href="/downloads/day/JSD2025_Workshoptag-Uebersicht.pdf"
                  target="_blank"
                >
                  Download (PDF, 2 MB)
                </a>
                .<br></br>
                <br></br>
                <a
                  href="https://backoffice.jugsaxony.org/#/booking/TICKETS-JSD2025"
                  target="_blank"
                  className="button is-dark"
                >
                  Jetzt Dein Kombiticket sichern
                </a>
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default WorshopJsdIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    imgOliver: allFile(
      filter: { relativePath: { eq: "people/oliver-drotbohm_500.jpg" } }
    ) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            src
          }
        }
      }
    }
    imgStephan: allFile(
      filter: { relativePath: { eq: "people/stephan-pirnbaum_500.jpg" } }
    ) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            src
          }
        }
      }
    }
    imgJulia: allFile(
      filter: { relativePath: { eq: "people/julia-dellnitz_500.jpg" } }
    ) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            src
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/day/(workshoptag.md)/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          fileAbsolutePath
          frontmatter {
            title
            subtitle
            date
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`
